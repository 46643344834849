<template>
  <div :class="$style.wrapper">
    <a-spin :spinning="loading" tip="页面加载中..." />
    <iframe ref="Iframe" :src="url" :class="$style.iframe"> </iframe>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component()
export default class CustomLink extends Vue {
  @Prop({ type: String, default: '' }) url;

  mounted() {
    this.iframeLoad();
  }
  loading = false;
  @Watch('url')
  iframeLoad() {
    this.loading = true;
    const iframe = this.$refs.Iframe;
    // 兼容处理
    if (iframe.attachEvent) {
      // IE
      iframe.attachEvent('onload', () => {
        this.loading = false;
      });
    } else {
      // 非IE
      iframe.onload = () => {
        this.loading = false;
      };
    }
  }
}
</script>

<style lang="less" module>
.wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .iframe {
    height: 100%;
    width: 100%;
    margin: 0;
    border: 0;
  }
  :global {
    .ant-spin-dot-item {
      background-color: var(--screen-font-1);
    }
    .ant-spin-text {
      color: var(--screen-font-1);
    }
  }
}
</style>
